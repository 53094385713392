<template>
  <AppPage container :menuItems="menuItems">
    <input
      type="file"
      ref="fileInput"
      class="d-none"
      accept="image/*"
      @change="getBrowserImage"
    />

    <div class="text-center" v-if="user">
      <loader :loading="avatarLoading" height="100px" color="primary">
        <v-avatar
          size="100"
          @click="openFileExplorer"
          :color="!user.avatar ? 'primary' : 'transparent'"
          v-ripple
        >
          <img v-if="user.avatar" :src="user.avatar" :alt="user.name" />

          <span v-else class="white--text text-h5 uppercase">{{
            user.name.substring(0, 2)
          }}</span>
        </v-avatar>
      </loader>

      <h2 class="user-name">{{ user.name }}</h2>
      <p class="user-email">{{ user.email }}</p>
    </div>

    <div class="list">
      <div
        class="list--item"
        v-for="(list, index) in lists"
        :key="index"
        v-ripple
        @click="$router.push(list.to)"
      >
        <div class="detail">
          <v-img :src="list.icon" size="50"></v-img>

          <div class="total">
            <p class="bold">{{ list.count }}</p>
            <p class="light">{{ list.title }}</p>
          </div>
        </div>

        <v-icon color="black">mdi-arrow-right</v-icon>
      </div>
    </div>

    <div class="my-5 terms">
      <router-link class="sm" to="/privacy-policy">{{
        $vuetify.lang.t("$vuetify.privacy_policy")
      }}</router-link>
      <router-link class="sm" to="/terms-conditions">{{
        $vuetify.lang.t("$vuetify.terms_and_conditions")
      }}</router-link>
    </div>

    <div class="text-center">
      <v-btn text color="red capitalize" @click="logUserOut" :loading="loading">
        <v-icon class="mr-2">mdi-logout</v-icon>
        {{ $vuetify.lang.t("$vuetify.logout") }}
      </v-btn>
    </div>
  </AppPage>
</template>

<script>
import MediaPicker from "@/mixin/get-mob-media";

import { bytesToMb } from "@/helpers";
import { MAX_PROFILE_IMAGE_SIZE } from "@/constants";
import { createNamespacedHelpers, mapState } from "vuex";
const userModule = createNamespacedHelpers("user");
const petModule = createNamespacedHelpers("pet");

export default {
  name: "profile",
  mixins: [MediaPicker],
  data() {
    return {};
  },
  computed: {
    ...mapState(["isCordova"]),
    ...userModule.mapState(["user", "loading", "postCount", "avatarLoading"]),
    menuItems() {
      return [
        {
          action: () => this.openFileExplorer(),
          title: this.$vuetify.lang.t("$vuetify.upload_profile_image"),
        },
      ];
    },
    lists() {
      const { adoptionCount, cuteCount, postCount } = this.postCount;
      let adoption = adoptionCount || 0;
      let cuteList = cuteCount || 0;
      let post = postCount || 0;

      adoption < 10 && (adoption = "0" + adoption);
      cuteList < 10 && (cuteList = "0" + cuteList);
      post < 10 && (post = "0" + post);

      return [
        {
          id: 1,
          title: this.$vuetify.lang.t("$vuetify.adoption_requests"),
          count: adoption,
          icon: require("@/assets/svg/adoption-request.svg"),
          to: "/adoption-list",
        },
        {
          id: 3,
          title: this.$vuetify.lang.t("$vuetify.feed_post"),
          count: post,
          icon: require("@/assets/svg/feed-list.svg"),
          to: "/my-posts",
        },
        {
          id: 2,
          title: this.$vuetify.lang.t("$vuetify.cute_list"),
          count: cuteList,
          icon: require("@/assets/svg/cute-list.svg"),
          to: "/cute-list",
        },
        {
          id: 2,
          count: this.$vuetify.lang.t("$vuetify.general_info"),
          icon: require("@/assets/svg/general-info.svg"),
          to: "/general-info",
        },
      ];
    },
  },
  methods: {
    ...petModule.mapMutations(["CLEAR_PETS_LIST"]),
    ...userModule.mapMutations(["CLEAR_ALL_USER_STATE"]),
    ...userModule.mapActions([
      "LOGOUT",
      "UPDATE_USER_AVATAR",
      "GET_POST_COUNT",
    ]),
    async openFileExplorer() {
      this.$refs.fileInput.click();
      // try {
      //   if (!this.isCordova) {
      //   } else {
      //     const medias = await this.openMediaPicker({
      //       selectMode: 100,
      //       maxSelectCount: 1,
      //       maxSelectSize: mbToBytes(1.8),
      //     });

      //     if (medias.length) {
      //       const { file } = medias[0];
      //       if (file) this.uploadAvatar(file);
      //     }
      //   }
      // } catch (error) {
      //   console.log(error);
      //   window.showAlert(error, "Failed to get the image");
      // }
    },
    getBrowserImage(e) {
      const files = e.target.files;
      if (files.length) {
        const file = files[0];
        const { size } = file;

        if (bytesToMb(size) < MAX_PROFILE_IMAGE_SIZE) {
          this.uploadAvatar(file);
        } else {
          window.showAlert(
            "File Size Cannot be greater then 5 MB.",
            "File Limit Exceed"
          );
        }
      }
      e.target.files = null;
    },
    uploadAvatar(file) {
      const fd = new FormData();
      fd.append("media[1]", file);
      this.UPDATE_USER_AVATAR(fd);
    },
    logUserOut() {
      this.LOGOUT();
      this.CLEAR_ALL_USER_STATE();
      this.CLEAR_PETS_LIST();
    },
  },
  created() {
    this.GET_POST_COUNT();
  },
};
</script>

<style lang="scss" scoped>
.sm {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #333;
  text-decoration: none;
}
.list {
  background: rgba(246, 193, 90, 0.1);
  border-radius: 12px;
  overflow: hidden;
  margin-top: 2rem;
  margin-left: 0.7rem;
  margin-right: 0.7rem;

  &--item {
    padding: 0.8rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
    }

    .detail {
      display: flex;
      align-items: center;

      p {
        margin-bottom: 0;
      }
      .total {
        margin-left: 1rem;
        p.bold {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: #000000;
        }

        p.light {
          font-weight: normal;
          font-size: 10px;
          line-height: 16px;
          color: #000000;
          opacity: 0.5;
        }
      }
    }
  }
}

.terms {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.user-name {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #000000;
  margin-top: 10px;
}

.user-email {
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: #000000;
  opacity: 0.5;
  margin-bottom: 0;
}
</style>
